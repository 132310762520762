import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Tooltip,
} from "chart.js";

import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
// Registro dos componentes do Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface Skill {
	descricao: string;
	carga_horaria_aproveitada: number;
	carga_horaria_maxima: number;
}

interface SkillsChartProps {
	data: Skill[];
}

const SkillsChart: React.FC<SkillsChartProps> = ({ data }) => {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
	const chartRef = useRef<ChartJS<"bar"> | null>(null); // Referência ao gráfico

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 600);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (!Array.isArray(data) || data.length === 0) {
		return <div>Nenhum dado disponível para exibir o gráfico.</div>;
	}

	const chartData = {
		labels: data.map((skill) => skill.descricao),
		datasets: [
			{
				label: "Carga Horária Aproveitada",
				data: data.map((skill) => skill.carga_horaria_aproveitada),
				backgroundColor: "rgba(88, 86, 214, 0.8)",
				borderColor: "rgba(88, 86, 214, 1)",
				borderWidth: 1,
			},
			{
				label: "Carga Horária Máxima",
				data: data.map((skill) => skill.carga_horaria_maxima),
				backgroundColor: "rgba(140, 138, 138, 0.27)",
				borderColor: "rgba(140, 138, 138, 0.30)",
				borderWidth: 1,
			},
		],
	};

	const options: ChartOptions<"bar"> = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: "y",
		plugins: {
			tooltip: {
				callbacks: {
					label: (context) => {
						const value = context.raw as number;
						return `${context.dataset.label}: ${Math.round(
							value
						)}H`;
					},
				},
			},
			legend: {
				display: true,
			},
		},
		scales: {
			x: {
				beginAtZero: true,
				max: 100,
			},
			y: {
				ticks: {
					display: !isSmallScreen, // Oculta labels no eixo Y em telas pequenas
				},
			},
		},
		animation: {
			onComplete: () => {
				if (!chartRef.current) return; // Verifique se o gráfico está disponível
				const chart = chartRef.current;
				const ctx = chart.ctx;

				chart.data.datasets.forEach((dataset, datasetIndex) => {
					const meta = chart.getDatasetMeta(datasetIndex);
					meta.data.forEach((bar, index) => {
						const value = dataset.data[index] as number;
						const roundedValue = Math.round(value);
						const xPos = bar.x + 10; // Ajuste horizontal
						const yPos = bar.y + 4; // Ajuste vertical
						ctx.textAlign = "start";
						ctx.fillText(`${roundedValue}h`, xPos, yPos);
					});
				});
			},
		},
	};

	// Ajuste responsivo: Altura do gráfico varia conforme o tamanho da tela
	const containerStyle = {
		width: "100%",
		height: isSmallScreen ? "500px" : "600px", // Altura maior para telas grandes
	};

	return (
		<div style={containerStyle}>
			<Bar data={chartData} options={options} ref={chartRef} />
		</div>
	);
};

export default SkillsChart;
