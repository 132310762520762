import React, { useState } from "react";
import { Tabs, Tab, Box, Container } from "@mui/material";

interface TabsComponentProps {
  tabLabels: string[]; // Rótulos das abas
  tabContents: React.ReactNode[]; // Conteúdo correspondente a cada aba
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabLabels, tabContents }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center", // Centraliza horizontalmente
          alignItems: "center", // Alinha verticalmente (opcional)
          p:3
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          centered // Propriedade que ajuda na centralização
        >
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </Container>
      <Box mt={2}>{tabContents[selectedTab]}</Box>
    </Box>
  );
};

export default TabsComponent;
