import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ModalContextProvider } from "../../../context/Modal/modal.context";
import Eventos from "./components/Eventos";
import ExperienciasProfissionais from "./components/ExperienciasProfissionais";
import MeuPerfil from "./components/Perfil";
import SkillsChart from "./components/SkillsChart/SkillsChart";
import TabsComponent from "./components/TabsComponent/TabsComponent";
// import { MeuCurriculoContextProvider } from "./context";
import { useMeuCurriculo } from "./../../Private/MeuCurriculo/context";

interface Skill {
	descricao: string;
	carga_horaria_aproveitada: number;
	carga_horaria_maxima: number;
}

function MeuCurriculo() {
	const tabLabels = ["Currículo", "Skills"];

	const [skillsData, setSkillsData] = useState<Skill[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const { student } = useMeuCurriculo();	
	
	// Função para buscar dados da API
	useEffect(() => {
		const fetchSkillsData = async () => {
			const data: any = JSON.parse(
				String(localStorage.getItem("@palco-profissional:state"))
				);
				console.log("DATAAAAAAA", data);

				const ra_aluno = data?.usuario?.id;
				const id_matriz = data?.cursos[data?.cursos?.length - 1]?.id_matriz;
				
				console.log("ALUNO:", student.student.ra);
				console.log("MATRIZ:", id_matriz);
				
				if ((ra_aluno && id_matriz) || (!!student.student.ra)) {
					
				try {

					// 1. Faz a requisição para pegar os cursos do aluno	
					const cursosResponse = await axios.get(
						`https://apigateway.uninta.edu.br/ead/ecossistema/alunos/${!!ra_aluno ? ra_aluno : student.student.ra}/cursos`
					);

					const cursosData = cursosResponse.data;
					// console.log("CURSOS DO ALUNO:", cursosData);

					// Acessando o último curso e extraindo o id_matriz da última matriz
					const cursos = cursosData?.data || [];
					const ultimoCurso = cursos[cursos.length - 1]; // Pegando o último curso (mais recente)

					if (!ultimoCurso) {
						setError("Nenhum curso encontrado.");
						setLoading(false);
						return;
					}

					// Extraindo o id_matriz do último curso
					const id_matriz = ultimoCurso?.id_matriz;
					console.log("ID MATRIZ:", id_matriz);

					// 2. Faz a requisição para pegar os cursos do aluno e a matriz
					const response = await axios.get(
						`https://apigateway.uninta.edu.br/ead/educacional/alunos/${!!ra_aluno ? ra_aluno : student.student.ra}/matrizes/${id_matriz}/componentes`
					);	

					// Verificação e transformação dos dados
					const responseData = response.data.data || [];
					const formattedData = responseData.flatMap((item: any) =>
						item.modalidades.map((modalidade: any) => ({
							descricao: modalidade.descricao || "Sem descrição",
							carga_horaria_aproveitada:
								parseFloat(
									modalidade.carga_horaria_aproveitada
								) || 0,
							carga_horaria_maxima:
								parseFloat(modalidade.carga_horaria_maxima) ||
								0,
						}))
					);

					setSkillsData(formattedData);
					setLoading(false);
				} catch (err: any) {
					setError(err.message || "Erro ao buscar os dados.");
					setLoading(false);
				}
			}
		};

		fetchSkillsData();
	}, [student]);

	const tabContents = [
		// Conteúdo da aba "Currículo"
		<Grid container spacing={2} sx={{ mt: 3 }} key="curriculo">
			<MeuPerfil />
			<ExperienciasProfissionais />
			<Eventos />
		</Grid>,

		// Conteúdo da aba "Skills"
		<Container sx={{ mt: 3, p: 3 }} key="skills">
			<Grid item md={12} spacing={2}>
				<Box>
					<Typography variant="h5" sx={{ mb: 2 }}>
						Atividades Complementares
						{loading ? (
							"Carregando..."
						) : error ? (
							<span style={{ color: "red" }}>{error}</span>
						) : (
							<SkillsChart data={skillsData} />
						)}
					</Typography>
				</Box>
			</Grid>
		</Container>,
	];

	return (
		// <MeuCurriculoContextProvider>
			<ModalContextProvider>
				<Container maxWidth="xl" sx={{ mt: 3 }}>
					<TabsComponent
						tabLabels={tabLabels}
						tabContents={tabContents}
					/>
				</Container>
			</ModalContextProvider>
		// </MeuCurriculoContextProvider>
	);
}

export default MeuCurriculo;
