/**
 * ## MeuComponente: Componente ReactJS funcional para exibir o perfil do aluno.
 *
 * Este componente não recebe props, porém utiliza dos dados do contexto MeuCurriculoContext.
 *
 * ## Exemplo de uso:
 *
 * ```javascript|typescript
 * const App = () => {
 *   return <MeuPerfil />;
 * };
 * ```
 *
 * ## Props:
 *
 * * `none`: Nenhuma prop necessária.
 *
 * ## Funções:
 *
 * * `formatarSaudacao(nome)`: Formata a saudação de acordo com o valor da prop 'nome'.
 */

// Importações
import {
	CalendarMonth,
	Close,
	Edit,
	Home,
	Mail,
	ManageAccounts,
	Phone,
	School,
} from "@mui/icons-material";
import {
	Avatar,
	Box,
	Dialog,
	Grid,
	IconButton,
	Paper,
	Typography,
	useTheme,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { Fragment, useState } from "react";
import { useAuthContext } from "../../../../../context/Authentication/hooks/useAuth";
import { useMeuCurriculo } from "../../context";
import PerfilForm from "./PerfilForm";

// Código do componente
function MeuPerfil() {
	const theme = useTheme();
	// const history = useNavigate();
	const { auth } = useAuthContext();
	// const { state } = usePalcoProfissional();
	// const { perfil, loadProfile, loadProfileById } = useProfile();
	const [, setReload] = useState<boolean>(false);
	const { student } = useMeuCurriculo();

	// Controlador do formulário de experiencia profissional
	const [, setOpen] = useState<boolean>(false);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);

	// Controlador do formulário de perfil do usuário
	const [openProfileEditor, setOpenProfileEditor] = useState<boolean>(false);
	const handleOpenProfileEditor = () => setOpenProfileEditor(true);
	const handleCloseProfileEditor = () => setOpenProfileEditor(false);

	// Carrega dados de perfil do usuário
	// useEffect(() => {
	// 	if (auth.role !== "student") {
	// 		loadProfileById();
	// 	} else {
	// 		loadProfile();
	// 	}
	// 	// eslint-disable-next-line
	// }, [reload]);

	// useEffect(() => {
	// 	if (auth.role !== "student") {
	// 		loadProfileById();
	// 	} else {
	// 		loadProfile();
	// 	}
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<Fragment>
			<Dialog
				open={openProfileEditor}
				onClose={handleCloseProfileEditor}
				maxWidth="md"
				fullWidth
			>
				<Box
					style={{
						backgroundColor: theme.palette.primary.main,
						color: "white",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "12px",
					}}
				>
					<Box p={2} sx={{ display: "flex", gap: "12px" }}>
						<ManageAccounts />
						<Typography variant="body1">Editar perfil</Typography>
					</Box>
					<Box p={1}>
						<IconButton
							size="small"
							sx={{
								color: "white",
							}}
							onClick={handleCloseProfileEditor}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
				<PerfilForm
					setOpen={setOpen}
					handleCloseProfileEditor={handleCloseProfileEditor}
					setReload={setReload}
				/>
			</Dialog>
			{/* <pre>{JSON.stringify(student, null, 4)}</pre> */}

			{/* <Grid item md={12}>
				<Box>
					<Button
						onClick={() => {
							history(-1);
						}}
						sx={{ mt: 3, mb: 3 }}
						startIcon={<ArrowBack />}
						variant="contained"
						disableElevation
					>
						Voltar
					</Button>
				</Box>
			</Grid> */}

			<Grid
				item
				md={6}
				sx={
					{
						// mt: 6,
						// border: "solid purple",
						// maxHeight: "60vh",
						// overflowY: "scroll",
					}
				}
			>
				<Box
					// mt={3}
					// p={3}
					sx={{
						display: "flex",
						alignItems: "center",
						jusitifyContent: "flex-start",
						gap: "12px",
						mb: 2,
					}}
				>
					<Typography variant="h5">Perfil</Typography>
					<IconButton
						size="small"
						onClick={handleOpenProfileEditor}
						sx={{
							bgcolor: theme.palette.primary.main,
							color: "white",
							display:
								auth.role === "admin" ||
								auth.role === "representante_empresa"
									? "none"
									: "",
						}}
					>
						<Edit />
					</IconButton>
				</Box>
				<Paper elevation={0}>
					<Box
						// mt={3}
						p={3}
						sx={{
							display: "flex",
							alignItems: "flex-start",
							jusitifyContent: "flex-start",
							gap: "26px",
							// minHeight: "250px",
						}}
					>
						<Avatar
							src={student?.student?.profile_picture}
							sx={{
								bgcolor: amber["A400"],
								width: 68,
								height: 68,
							}}
						>
							<Typography>
								{student?.user?.name
									? student.user.name[0]
									: "0"}
							</Typography>
						</Avatar>
						<Box>
							<Typography variant="h5">
								{student?.user?.name}
							</Typography>
							<Typography variant="caption">
								{(student &&
									student.student &&
									student.student.course &&
									student?.student?.course?.name
										.toLowerCase()
										.split(" ")
										.map(
											(word) =>
												word.charAt(0).toUpperCase() +
												word.slice(1)
										)
										.join(" ")) ??
									"Curso não informado"}{" "}
							</Typography>
						</Box>
					</Box>
				</Paper>
				<Paper
					elevation={0}
					// sx={{
					// 	//display:
					// 		student && student.student !== null ? "" : "none",
					// }}
				>
					<Box
						p={3}
						mt={2}
						sx={
							{
								// border: "solid purple",
							}
						}
					>
						<Typography variant="body1" sx={{ mt: 1 }}>
							{student?.student?.profile}
						</Typography>

						<Typography
							sx={{
								display: student?.student?.birthday
									? "flex"
									: "none",
								gap: "12px",
								alignItems: "center",
								mt: 3,
								mb: 2,
							}}
						>
							{" "}
							<CalendarMonth /> Aniversário:{" "}
							{student?.student?.birthday
								? new Date(
										student.student.birthday
								  ).toLocaleDateString()
								: "Aniversário não informado pelo usuário"}
							{/* <pre>{JSON.stringify(perfil, null, 4)}</pre> */}
						</Typography>
						<Typography
							sx={{
								display:
									student && student?.student?.address
										? "flex"
										: "none",
								gap: "12px",
								alignItems: "flex-start",
								mb: 2,
							}}
						>
							{" "}
							<Home />
							{student?.student?.address
								? `${student.student.address}, ${student.student.cep}, ${student.student.city} - ${student.student.state}`
								: "Endereço não informado"}
						</Typography>
						<Typography
							sx={{
								display: student?.user?.email ? "flex" : "none",
								gap: "12px",
								alignItems: "flex-start",
								mb: 2,
							}}
						>
							{" "}
							<Mail />
							{student && student.user
								? student.user.email.toLowerCase()
								: "Email não informado"}
						</Typography>
						<Typography
							sx={{
								display:
									student &&
									student.student &&
									student.student.phone &&
									student.student.phone !== ""
										? "flex"
										: "none",
								gap: "12px",
								alignItems: "flex-start",
								mb: 2,
							}}
						>
							{" "}
							<Phone />
							{student && student?.phone !== ""
								? student.student.phone
								: "Telefone não informado pelo usuário"}
						</Typography>
						<Typography
							sx={{
								display:
									student &&
									student.student &&
									student.student.course
										? "flex"
										: "none",
								gap: "12px",
								alignItems: "flex-start",
								mb: 2,
							}}
						>
							{" "}
							<School />
							{student?.student?.course?.name
								.toLowerCase()
								.split(" ")
								.map(
									(word) =>
										word.charAt(0).toUpperCase() +
										word.slice(1)
								)
								.join(" ") ?? "Curso não informado"}{" "}
						</Typography>
					</Box>
				</Paper>
			</Grid>
		</Fragment>
	);
}

// Exporta o componente
export default MeuPerfil;
