import { AccountCircle } from "@mui/icons-material";
import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router";
import { Meta } from "../../../models/Meta";
import { TabelaUsuarios } from "../../../models/Tabelas";
import { getUsuarios } from "../../../services/crud/crud.usuario";
import { estaAtivo, verificarRole } from "../../../utils/funcoes-globais";
import Notify from "../../../utils/notification";
import ModalAlteracaoUsuario from "../../Modal/ModalAlteracao/ModalAlteracaoUsuario";
import ModalCadastroUsuario from "../../Modal/ModalCadastro/ModalCadastroUsuario";
import ModalDeleteUsuario from "../../Modal/ModalDelete/ModalDeleteUsuario";
import ModalDetalhesUsuario from "../../Modal/ModalDetalhes/ModalDetalhesUsuario";
import { useReloadContext } from "../hooks/useReload";
import "../style.scss";

function TabelaUsuariosView() {
	const navigate = useNavigate();
	const theme = useTheme();
	const [tableRows, setTableRows] = useState({} as TabelaUsuarios);
	const [paginacao, setPaginacao] = useState({} as Meta);
	const { setReload } = useReloadContext();
	// const { setDrawer, drawer } = useDrawerContext();

	/**
	 * @function handleChangePage
	 * Faz uma requisição para a api e traz as informações da paginação e o array de tipos de contrato.
	 * @param {object} event - Representa o elemento que sofreu o evento.
	 * @param {number} page - Representa a página atual.
	 */

	function handleChangePage(event: object, page: number) {
		getUsuarios(`${page}`, `${role}`).then((data) => {
			setPaginacao(data.meta);
			setTableRows({
				usuarios: data.data,
			});
		});
	}

	/**
	 * @function handleUsuario
	 * Verifica se o usuário não tem empresas cadastradas, caso sim exibe um Notify, senão salva uma chave no sessionStorage de nome id_usuario_filter cujo o valor será o id do usuário selecinado.
	 * @param {number} id - Id do usuário selecionado.
	 * @param {number} index - Representa uma posição no array de usuários.
	 */
	function handleUsuario(id: number, index: number) {
		if (tableRows.usuarios[index].companies.length === 0) {
			Notify("Esse usuário não tem empresas cadastradas!", "warning");
		} else {
			localStorage.setItem("id_usuario_filter", id.toString());
			navigate(`/empresas`);
		}
	}

	const fetchUsuarios = () => {
		getUsuarios(null, `${role}`)
			.then((data) => {
				setPaginacao(data.meta);
				setTableRows({ usuarios: data.data });
			})
			.catch((e) => {
				console.log(e);
			});

		setReload(true);
		// eslint-disable-next-line
	};

	const [role, setRole] = React.useState(2); // Initial state
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		fetchUsuarios();
		// eslint-disable-next-line
	}, [role]);

	const [loading, setLoading] = useState(false);

	const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const term = event.target.value;
		setSearchTerm(term);

		if (term) {
			setLoading(true); // Ativa o spinner

			try {
				const { data } = await getUsuarios(null, null, term);
				const filteredUsuarios = data;
				setTableRows({ usuarios: filteredUsuarios });
			} catch (error) {
				console.error("Erro ao buscar usuários:", error);
			} finally {
				setLoading(false); // Desativa o spinner após a busca
			}
		} else {
			fetchUsuarios();
		}
	};

	const handleRoleChange = (event) => {
		setRole(event.target.value);
		getUsuarios(`${1}`, `${event.target.value}`).then((data) => {
			setPaginacao(data.meta);
			setTableRows({
				usuarios: data.data,
			});
		});
	};

	return (
		<div className="boxTabelaUser" data-testid="tabela-usuario">
			<Paper className="divPaper" sx={{ mt: 2 }} elevation={1}>
				<Box
					p={3}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<Typography variant="h5">Usuários</Typography>
						<Typography variant="body2">
							Listando todos os usuários cadastrados no sistema
						</Typography>
					</Box>

					<Box>
						<FormControl fullWidth>
							<InputLabel id="role-select-label">
								Função
							</InputLabel>
							<Select
								labelId="role-select-label"
								id="role-select"
								value={role}
								label="Role"
								onChange={handleRoleChange}
							>
								<MenuItem value={1}>Admin</MenuItem>
								<MenuItem value={2}>Representante</MenuItem>
								<MenuItem value={3}>Estudante</MenuItem>
							</Select>
						</FormControl>
					</Box>

					<Box display="flex" alignItems="center">
						<FormControl>
							<InputLabel></InputLabel>
							<TextField
								label="Buscar Usuário"
								variant="outlined"
								value={searchTerm}
								onChange={handleSearch}
								sx={{ ml: 2, width: "350px" }}
							/>
						</FormControl>
						{loading && (
							<Spinner
								animation="grow"
								variant="danger"
								role="status"
								style={{ marginLeft: "16px" }}
							>
								<span className="visually-hidden">
									Carregando...
								</span>
							</Spinner>
						)}{" "}
						{/* Spinner ao lado do campo de busca */}
					</Box>

					<ModalCadastroUsuario />
				</Box>

				<TableContainer className="tableContainer">
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow hover role="checkbox" tabIndex={-1}>
								<TableCell align="left">Nome</TableCell>
								<TableCell align="left">E-mail</TableCell>
								<TableCell align="center">Função</TableCell>
								<TableCell align="center">Ativo</TableCell>
								<TableCell align="center">Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.usuarios?.map((usuario, index) => (
								<TableRow key={index}>
									<TableCell
										align="left"
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "12px",
										}}
									>
										<Tooltip title="Ver empresas">
											<>
												<IconButton
													onClick={() => {
														handleUsuario(
															usuario.id,
															index
														);
													}}
													title="Empresas do usuario"
												>
													<AccountCircle
														sx={{
															color: theme.palette
																.secondary.main,
														}}
													/>
												</IconButton>
											</>
										</Tooltip>
										{usuario.name.toUpperCase()}
									</TableCell>
									<TableCell align="left">
										<Box>{usuario.email}</Box>
									</TableCell>
									<TableCell align="center">
										{verificarRole(usuario?.role_id)}
									</TableCell>
									<TableCell align="center">
										{estaAtivo(Boolean(usuario?.is_active))}
									</TableCell>

									<TableCell align="center">
										<ModalAlteracaoUsuario
											userId={usuario.id}
											userRoleId={usuario.role_id}
										/>

										<ModalDetalhesUsuario
											userId={usuario.id}
										/>

										<ModalDeleteUsuario
											userId={usuario.id}
											dados={{
												tableRows: tableRows,
												setTableRows: setTableRows,
											}}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<Stack alignItems="center" spacing={2} sx={{ p: 1 }}>
					<Pagination
						count={
							typeof paginacao === "undefined"
								? 1
								: paginacao.last_page
						}
						onChange={handleChangePage}
					/>
				</Stack>
			</Paper>
			{/* <MenuNavegacao /> */}
		</div>
	);
}

export default TabelaUsuariosView;
